<template>
  <div class="horarios-agendamentos col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <v-card class="mb-5">
      <v-card-text class="pt-1">
        <v-snackbar
            v-model="snackbar"
            :timeout="3000"
            color="success"
            fixed
            right
            top
            elevation="24"
        >
          <h4>Sucesso!</h4>
          <h5>{{ snackText }}</h5>
        </v-snackbar>

        <!-- Filtros e Botões -->
        <v-row class="mt-3 v-data-table" style="margin-top: 12px !important;">
          <v-col class="py-0 col-12 col-md-6 col-lg-6">
            <EmpresasSelect v-model="empresaFilterValue"/>
          </v-col>

          <v-col class="py-0 col-12 col-md-6 col-lg-6">
            <ProducoesSelect
              v-model="producaoFilterValue"
              :empresa="empresaFilterValue"
              @input="getHorarios"
            />
          </v-col>

          <v-col class="py-0 col-6 col-md-3 col-lg-3">
            <v-btn
                color="info"
                outlined
                :disabled="disabledBtnEditar"
                @click="habilitarEdicao"
            >
              Editar
            </v-btn>
          </v-col>

          <v-col class="py-0 col-6 col-md-3 col-lg-3">
            <v-btn
                color="info"
                outlined
                :disabled="disabledBtnSalvar"
                @click="dialogConfirmacaoSalvar = true"
            >
              Salvar
            </v-btn>
          </v-col>

          <v-col class="py-0 col-6 col-md-3 col-lg-3">
            <v-btn
                color="info"
                outlined
                :disabled="disabledBtnCancelar"
                @click="dialogCancelarAlteracoes = true"
            >
              Cancelar
            </v-btn>
          </v-col>

          <v-col class="py-0 col-6 col-md-3 col-lg-3">
            <v-btn
                color="info"
                outlined
                :disabled="disabledBtnAdicionar"
                @click="adicionarHorario"
            >
              Adicionar
            </v-btn>
          </v-col>
        </v-row>

        <!-- Dados -->
        <v-row>
          <v-col>
            <v-data-table
                :headers="headers"
                :items="data"
                :loading="loading"
                loading-text="Carregando..."
                :hide-default-footer="true"
                disable-pagination
            >
              <template v-slot:item.horario="props">
                <div v-if="props.item.bloqueado" class="d-flex">
                  <v-text-field
                      v-model="props.item.horario"
                      outlined
                      dense
                      disabled="true"
                      type="time"
                  />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          color="red"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Não é possível editar/excluir esse horário, existe(m) pedido(s) agendado(s) não faturado(s).</span>
                  </v-tooltip>
                </div>
                <v-text-field
                    v-if="!props.item.bloqueado"
                    v-model="props.item.horario"
                    outlined
                    dense
                    :disabled="disabledTxtHorario"
                    type="time"
                    @change="validarHorario(props.index, props.item.horario)"
                />
              </template>
              <template v-slot:item.excluir="{item}">
                <v-btn outlined color="red" disabled="true" v-if="item.bloqueado">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn outlined color="red" :disabled="disabledBtnExcluir" @click="removerHorario(item.horario)" v-if="!item.bloqueado">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>Nenhum registro encontrado</template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- Dialogs -->
        <v-row>
          <v-col>
            <v-dialog v-model="dialogAlerta" max-width="500px">
              <v-card class="bg-warning text-white">
                <v-card-title class="headline word-breaker d-flex justify-center">
                  {{ mensagemDialogAlerta }}
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                      color="white"
                      text
                      outlined
                      large
                      @click="dialogAlerta = false"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfirmacaoSalvar" max-width="500px">
              <v-card class="bg-danger text-white">
                <v-card-title class="headline word-breaker d-flex justify-center">
                  Tem certeza que deseja salvar os horários?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="white"
                         text
                         outlined
                         large
                         @click="dialogConfirmacaoSalvar = false"
                  >
                    Não
                  </v-btn>
                  <v-btn color="white" text outlined large @click="salvarHorarios">
                    Sim
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCancelarAlteracoes" max-width="500px">
              <v-card class="bg-danger text-white">
                <v-card-title class="headline word-breaker d-flex justify-center">
                  Tem certeza que deseja cancelar as alterações?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="white"
                         text
                         outlined
                         large
                         @click="dialogCancelarAlteracoes = false"
                  >
                    Não
                  </v-btn>
                  <v-btn color="white" text outlined large @click="cancelarEdicao">
                    Sim
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data: () => ({
    // Snackbar
    snackbar: false,
    snackText: '',

    // Filtros
    empresaFilterValue: '',
    producaoFilterValue: '',
    empresasOptions: [],
    producao: [],
    empresasOptionsFilter: [],
    producaoOptionsFilter: [],

    // Botões
    disabledBtnEditar: true,
    disabledBtnAdicionar: true,
    disabledBtnExcluir: true,
    disabledBtnSalvar: true,
    disabledBtnCancelar: true,
    disabledTxtHorario: true,

    // Data-table
    loading: false,
    data: [],

    // Dialogs
    dialogAlerta: false,
    mensagemDialogAlerta: '',
    dialogConfirmacaoSalvar: false,
    dialogCancelarAlteracoes: false,
  }),

  computed: {
    headers () {
      return [
        { text: 'Horário', value: 'horario', sortable: false, width: '95%' },
        { text: 'Excluir', value: 'excluir', sortable: false, width: '5%' },
      ]
    },
  },

  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agricola', route: 'agricola' },
      { title: 'Horários de Agendamento' },
    ])

    this.initialize()
    this.getEmpresasOptions()
  },

  methods: {
    initialize () {},

    getEmpresasOptions () {
      this.empresasOptions = this.$empresas.list()
      this.empresasOptionsFilter = this.$empresas.list()
    },

    setProducoesOptionsFIlter (idEmpresa) {
      if (idEmpresa === '') {
        this.data = []
        this.disabledBtnEditar = true
        this.disabledBtnSalvar = true
        this.disabledBtnCancelar = true
        this.disabledBtnAdicionar = true
        this.disabledTxtHorario = true
        this.disabledBtnExcluir = true
      }

      this.producaoOptionsFilter = []

      if (idEmpresa !== 'Selecione...') {
        for (const empresa of this.empresasOptionsFilter) {
          if (idEmpresa === empresa.nome) {
            idEmpresa = empresa.id
          }
        }

        ApiService.get('/producao').then((res) => {
          const producoesFiltro = []
          producoesFiltro.push({ sigla: 'Selecione...', id: '' })

          for (const producao of res.data.data) {
            if (producao.empresa_id === idEmpresa) {
              producoesFiltro.push({
                id: producao.id,
                sigla: producao.sigla,
              })
            }
          }

          this.producaoOptionsFilter = producoesFiltro
          this.producaoFilterValue = ''
        })
      } else {
        const producoesFiltro = []

        producoesFiltro.push({
          sigla: 'Selecione...',
          id: '',
        })

        this.producaoOptionsFilter = producoesFiltro
        this.producaoFilterValue = ''
      }
    },

    setProducoesOptions (idEmpresa) {
      this.producoesOptions = []

      ApiService.get('/producao').then((res) => {
        const producoes = []

        for (const producao of res.data.data) {
          if (producao.empresa_id === idEmpresa) {
            producoes.push({
              id: producao.id,
              sigla: producao.sigla,
            })
          }
        }

        this.producao = producoes
      })
    },

    async getHorarios () {
      if (this.producaoFilterValue === null) {
        this.data = []
        this.disabledBtnEditar = true
        this.disabledBtnSalvar = true
        this.disabledBtnCancelar = true
        this.disabledBtnAdicionar = true
        this.disabledTxtHorario = true
        this.disabledBtnExcluir = true
        return
      }

      this.loading = true

      const horariosPedidos = await ApiService.get(
        '/horario-agendamento/empresa-producao',
          `?empresa_id=${this.empresaFilterValue}&producao_id=${this.producaoFilterValue}`,
      )

      const horarios = horariosPedidos.data.data.horarios
      const pedidos = horariosPedidos.data.data.pedidos

      const horariosDisponiveis = horarios.filter(function (o1) {
        return !pedidos.some(function (o2) {
          return o1.horario === o2.horario
        })
      })

      const horariosPedidosConcatenados = horariosDisponiveis.concat(pedidos)

      this.data = horariosPedidosConcatenados.sort((a, b) => (a.horario > b.horario) ? 1 : ((b.horario > a.horario) ? -1 : 0))
      this.loading = false
      this.disabledBtnEditar = false
    },

    habilitarEdicao () {
      this.disabledBtnEditar = true
      this.disabledBtnSalvar = false
      this.disabledBtnCancelar = false
      this.disabledBtnAdicionar = false
      this.disabledTxtHorario = false
      this.disabledBtnExcluir = false
    },

    async cancelarEdicao () {
      await this.getHorarios()
      this.disabledBtnEditar = false
      this.disabledBtnSalvar = true
      this.disabledBtnCancelar = true
      this.disabledBtnAdicionar = true
      this.disabledTxtHorario = true
      this.disabledBtnExcluir = true
      this.dialogCancelarAlteracoes = false
    },

    adicionarHorario () {
      this.disabledBtnSalvar = true
      this.disabledBtnAdicionar = true

      this.data.unshift({
        horario: '',
      })
    },

    removerHorario (horario) {
      this.data = this.data.filter(function (value) {
        return value.horario !== horario
      })

      if (this.data[0].horario !== '') {
        this.disabledBtnSalvar = false
        this.disabledBtnAdicionar = false
      }
    },

    async salvarHorarios () {
      this.dialogConfirmacaoSalvar = false
      this.loading = true
      this.data = [...new Map(this.data.map((item) => [item.horario, item])).values()]

      const params = {
        producao_id: this.producaoFilterValue,
        empresa_id: this.empresaFilterValue,
        horarios: this.data,
      }

      await ApiService.post('horario-agendamento', params)
      await this.getHorarios()
      await this.cancelarEdicao()

      this.snackbar = true
      this.snackText = 'Horários salvos com sucesso.'
      this.loading = false
    },

    validarHorario (index, horario) {
      const horarios = this.data.filter(function (value) {
        return value.horario === horario
      })

      if (horarios.length > 1) {
        this.dialogAlerta = true
        this.data[index].horario = ''
        this.mensagemDialogAlerta = 'Não é possível adicionar dois horários iguais!'
        return
      }

      this.disabledBtnSalvar = false
      this.disabledBtnAdicionar = false
    },
  },
}
</script>

<style scoped>
.horarios-agendamentos .v-data-table {
  align-items: center;
  margin: 0 auto !important;
}
.horarios-agendamentos .v-data-table .v-btn {
  height: 34px !important;
  width: 100%;
}
.horarios-agendamentos .v-text-field__details {
  display: none !important;
}
.horarios-agendamentos .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}
.horarios-agendamentos .row + .row {
  margin-top: 0;
}
@media (max-width: 960px) {
  .horarios-agendamentos .v-data-table .v-btn {
    margin-bottom: 5px;
  }
}
</style>
